import { InsertCatalogMutationVariables, useInsertCatalogMutation } from '../../../mutations/__generated__/insertCatalog.generated';
import { UpdateCatalogMutationVariables, useUpdateCatalogMutation } from '../../../mutations/__generated__/updateCatalog.generated';
import { useUpdateContractsMutation } from '../../../mutations/__generated__/updateContracts.generated';
import { CatalogsByCompanyIdDocument } from '../../../queries/__generated__/catalogsByCompanyId.generated';
import { ContractsAsBuyerDocument } from '../../../queries/__generated__/contractsAsBuyer.generated';
import { ContractsAsSupplierDocument } from '../../../queries/__generated__/contractsAsSupplier.generated';

export interface UpsertCatalogMutationVariables {
  upsertCatalogVariables: InsertCatalogMutationVariables | UpdateCatalogMutationVariables;
  addedContractIds?: string[];
  deletedContractIds?: string[];
}

export interface UseUpsertCatalogMutation {
  upsertCatalogMutation: (params: UpsertCatalogMutationVariables) => Promise<string | undefined>;
}

export default function UseUpsertCatalogMutation(): UseUpsertCatalogMutation {
  const [insertCatalog] = useInsertCatalogMutation({ refetchQueries: [CatalogsByCompanyIdDocument] });
  const [updateCatalog] = useUpdateCatalogMutation({ refetchQueries: [ContractsAsSupplierDocument] });
  const [updateContracts] = useUpdateContractsMutation({ refetchQueries: [CatalogsByCompanyIdDocument] });

  const upsertCatalogMutation = async ({ upsertCatalogVariables, addedContractIds, deletedContractIds }: UpsertCatalogMutationVariables): Promise<string | undefined> => {
    const updateContractRefetchQueries = [ContractsAsBuyerDocument, ContractsAsSupplierDocument];

    if ('id' in upsertCatalogVariables) {
      const { data: updateCatalogData } = await updateCatalog({ variables: upsertCatalogVariables });

      if (addedContractIds?.length) {
        await updateContracts({
          variables: {
            contractIds: addedContractIds,
            contractInput: {
              catalog_id: upsertCatalogVariables.id,
              discount: 0,
            },
          },
          refetchQueries: !deletedContractIds?.length ? updateContractRefetchQueries : undefined,
          awaitRefetchQueries: true,
        });
      }

      if (deletedContractIds?.length) {
        await updateContracts({
          variables: {
            contractIds: deletedContractIds!,
            contractInput: {
              catalog_id: null,
              discount: 0,
            },
          },
          refetchQueries: updateContractRefetchQueries,
          awaitRefetchQueries: true,
        });
      }

      return updateCatalogData?.update_catalog_by_pk?.id;
    }
    const insertCatalogOutput = await insertCatalog({
      variables: upsertCatalogVariables as InsertCatalogMutationVariables,
    });

    if (addedContractIds?.length) {
      await updateContracts({
        variables: {
          contractIds: addedContractIds,
          contractInput: {
            catalog_id: insertCatalogOutput.data!.insert_catalog_one!.id,
            discount: 0,
          },
        },
        refetchQueries: updateContractRefetchQueries,
        awaitRefetchQueries: true,
      });
    }

    return insertCatalogOutput.data?.insert_catalog_one?.id;
  };

  return {
    upsertCatalogMutation,
  };
}
